import React, { useEffect, useCallback } from 'react';
import { Button, Grid, Typography, Tooltip, InputAdornment, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AllPromotions from './AllPromotions';
import { useDispatch, useSelector } from 'react-redux';
import * as commonActions from "../../redux/actions/common"
import * as productsActions from "../../redux/actions/products"
import * as promotionsActions from "../../redux/actions/promotions"
import CustomInput from '../../components/common/CustomInput';
import SpecialProductsList from './SpecialProductsList';
import InfoIcon from '@material-ui/icons/Info';
import { useState } from 'react';
import CommonLoading from '../../components/common/CommonLoading';
import Select from 'react-select'
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import _ from 'lodash';

import CustomReactSelect from '../../components/common/CustomReactSelect';

function PromotionsPage() {
    const classes = useStyles();
    const dispatch = useDispatch()

    const { specialProducts = {}, allPromotions, isLoading } = useSelector(state => state.promotions)
    const [filters, setFilters] = useState({
        limit: 10,
        skip: 0,
        page: 0,
    })
    const [isActiveTab, setIsActiveTab] = useState(true)

    const [search, setSearch] = useState("");
    const [specialProductsList, setSpecialProductsLits] = useState([])
    const [syncLoader, setSyncLoader] = useState(false)
    const [filterStatus, setFilterStatus] = useState("All");


    const { values = [] } = specialProducts || {};
    const { values: promotionsValues = [] } = allPromotions || {};


    useEffect(() => {
        dispatch(productsActions.getAllProducts({ productTags: "featured", limit: 200 }))
        hitSpProductsAPI()
    }, [])

    useEffect(() => {
        if (search?.length === 0) hitGetAllPromotionAPI()
        else searchDebounce({ search })
    }, [search, filterStatus]);

    useEffect(() => {
        setSpecialProductsLits([...(values || [])])
    }, [values])

    //Promotion

    const hitGetAllPromotionAPI = () => {
        let filter;
        if (filterStatus === 'All') filter = ''
        else filter = filterStatus
        dispatch(promotionsActions.getAllPromotion({
            searchTerm: search,
            isActive: filter,
        }))
    }

    const handleConfirmationPromotion = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to Delete?",
            confirm: () => { handleDeletePromotion(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }

    const handleDeletePromotion = (id) => {
        dispatch(promotionsActions.deletePromotion({ promotionId: id }))
            .then(res => { hitGetAllPromotionAPI() })
            .catch(error => { })
    }

    const handleEditPromotion = (item) => {
        dispatch(commonActions.openCustomModalDialog({ data: item }, "EditPromotion"))
    }

    const syncPromotions = () => {
        setSyncLoader(true)
        promotionsActions.syncPromotion().then(() => {
            setSyncLoader(false)
            hitGetAllPromotionAPI()
        }).catch(() => setSyncLoader(false))
    }

    //Special Products
    const hitSpProductsAPI = () => {
        dispatch(promotionsActions.getSpecialProducts())
            .then((res) => { })
            .catch((error) => { })
    }

    const handleConfirmationProduct = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to Delete?",
            confirm: () => { handleDeleteProduct(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }

    const handleDeleteProduct = (id) => {
        let updatedList = [...specialProductsList]
        let items = updatedList.filter(v => v.id !== id)

        setSpecialProductsLits([...items])

        let newOrderIds = items.map(v => v.id)
        dispatch(promotionsActions.updateSpecialProducts({ products: newOrderIds }))
    }

    const addSpecialProduct = (id) => {
        dispatch(commonActions.openCustomModalDialog({}, "AddSpecialProduct"))
    }

    const onUpdateListOrder = (items) => {
        setSpecialProductsLits([...items])
        let newOrderIds = [...items].map(v => v.id)
        dispatch(promotionsActions.updateSpecialProducts({ products: newOrderIds }))
    }

    const onClickClearInputField = () => {
        if (!search) return
        const newFilters = { limit: 10, skip: 0, page: 0, search: '' }
        setFilters(newFilters)
        // hitRequest({ ...newFilters, cartType: isActiveTab })
        setSearch('')
    }

    const addPromotion = () => {
        dispatch(commonActions.openCustomModalDialog({}, "AddPromotions"))
    }


    const searchDebounce = useCallback(
        _.debounce(({ search }) => {
            if (search?.length) dispatch(promotionsActions.getAllPromotion({ searchTerm: search }));
        }, 1200),
        []
    );


    // const onChangeSelectedTab = (e) => {
    //     const status = e?.value;
    //     const newFilters = { limit: 10, skip: 0, page: 0, }
    //     setIsActiveTab(status)
    //     setFilters(newFilters)
    // }


    //filter Options
    const filterOptions = [
        { value: 'All', label: 'All' },
        { value: true, label: 'Active' },
        { value: false, label: 'In-Active' },
    ];

    //filter Change
    const onHandleChangeFilter = (e) => {
        const value = e?.value;
        setFilterStatus(value)
    }

    const colorStyles = {
        control: (styles) => ({ ...styles, width: '200px', height: '55px', backgroundColor: "#46952f", border: '1px solid gray', boxShadow: 'none', outline: 'none', cursor: 'pointer', borderRadius: 'none', '&:hover': { boxShadow: 'none', border: "1px solid black" }, borderColor: 'none', }),
        dropdownIndicator: base => ({
            ...base,
            color: "white",
            ":hover": {
                color: 'white'
            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: data?.color || "",
                outline: data?.outline || "",
                border: data?.border || "",
                backgroundColor: data?.backgroundColor || "",
                ":hover": {
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: "green",
                    color: 'white',
                    cursor: 'pointer',
                },

            };
        },
        singleValue: (styles, { data }) => ({ ...styles, color: 'white', fontWeight: '500', textTransform: "upperCase" }),
    };

    return (
        <Grid container component="main" className={`${classes.root} promotions-container`}>
            <Grid item xs={12} className="top-btn-sec">
                <Grid item xs={4} className="d-flex search-section">
                    <CustomInput
                        label="Search"
                        name="search"
                        placeholder="Search"
                        onChange={e => setSearch(e?.target?.value || "")}
                        value={search}
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            endAdornment: search ? <InputAdornment position="end" className="cur-pointer"><ClearIcon onClick={onClickClearInputField} /></InputAdornment> : null
                        }}
                    />
                </Grid>
                <Grid item xs={8} className="top-btn-sec" >
                    <Select
                        isSearchable={false}
                        defaultValue={filterOptions[0]}
                        onChange={onHandleChangeFilter}
                        options={filterOptions}
                        styles={colorStyles}
                    />
                    {/* code of Sync Promotion  */}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={syncPromotions}
                        style={{ height: '48px' }}
                    >
                        {syncLoader ? <CircularProgress size={20} style={{ color: "white" }} /> : 'Sync Promotions'}
                    </Button> */}

                    <div className='add-category-btn ml-1'>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={addPromotion}
                            className=""
                        >
                            Add Promotions
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <AllPromotions
                    handleDeletePromotion={handleConfirmationPromotion}
                    promotionsValues={promotionsValues || []}
                    handleEditPromotion={handleEditPromotion}

                />
            </Grid>

            {/* Code of special product and add product , remove for now */}
            {/* <Grid item xs={12} className="promotions-specials-sec">
                <div className="specials-top-sec">
                    <Typography className="info"><InfoIcon /> Products Visible in carousel at Home Page.</Typography>
                    <Button
                        onClick={() => { addSpecialProduct() }}
                        variant="contained"
                        color="primary"
                    >
                        Add Product
                    </Button>

                </div>
                <SpecialProductsList
                    handleDeleteList={handleConfirmationProduct}
                    list={specialProductsList || []}
                    onUpdateListOrder={onUpdateListOrder}
                />
            </Grid> */}

            <CommonLoading isLoading={isLoading} />
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },

}));


export default PromotionsPage