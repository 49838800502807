import React from 'react'
import Select from 'react-select'
import { colorStyles } from '../../utils'

const CustomReactSelect = (props) => {

  const { onChangeSelectedTab = () => { }, filterOptions = [] } = props


  return (
    <Select
      isSearchable={false}
      options={filterOptions}
      defaultValue={filterOptions[0]}
      onChange={onChangeSelectedTab}
      styles={colorStyles}
    />
  )
}

export default CustomReactSelect