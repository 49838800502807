import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { Typography } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CustomMultiSelect from '../../components/common/CustomMultiSelect';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';



function SpecialProductsList(props) {
    const classes = useStyles();
    const history = useHistory();

    const {
        list = [],
        handleChangeSubCategory,
        handleDeleteList,
        values,
        onUpdateListOrder,
        handleEditCategory
    } = props

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            list,
            result.source.index,
            result.destination.index
        );
        onUpdateListOrder(items)
    }


    return (
        <Grid item xs={12} className="special-products-listing">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="specialproducts">
                    {(provided, snapshot) => (
                        <div
                            className="delivery-partner"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {
                                list && list.length ? list.map((item, index) => {
                                    // let imgUrl = item && item.images && item.images.length && item.images[0] || null
                                    const { id, name, category, flowerType } = item
                                    return (
                                        <div className="" key={index}>
                                            <Draggable key={index} draggableId={`${index}`} index={index}>
                                                {(provided, snapshot) => (
                                                    <Paper className="d-flex special-sec-list"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    // style={getItemStyle(
                                                    //     snapshot.isDragging,
                                                    //     provided.draggableProps.style
                                                    // )}
                                                    >
                                                        <Typography variant="h6" className="special-indexing cur-pointer">
                                                            <MenuIcon />
                                                        </Typography>
                                                        {name || "-"}
                                                        {" "}
                                                        ({category.name || "-"})
                                                        {" "}
                                                        ({flowerType || "-"})
                                                        <div className="del-special-product cur-pointer" onClick={() => { handleDeleteList(id) }}>
                                                            <DeleteOutlineIcon />
                                                        </div>
                                                        {/* <div className="edit-category" onClick={() => { handleEditCategory(item) }}>
                                                            <EditIcon />
                                                        </div> */}
                                                    </Paper>
                                                )
                                                }

                                            </Draggable>

                                        </div>

                                    )
                                })
                                    :
                                    <div className="no-data">
                                        <img src="/images/noDataFound.svg" alt="" />
                                        <Typography variant="h5">No Featured Products Yet</Typography>
                                    </div>
                            }
                        </div>
                    )}
                </Droppable>
            </DragDropContext>


        </Grid>

    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },

}));


export default SpecialProductsList




// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};




