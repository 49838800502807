import React, { useCallback, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Container, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import * as productsActions from "../../redux/actions/products"
import * as authActions from "../../redux/actions/auth"
import AllProducts from './AllProducts';
import CustomInput from '../../components/common/CustomInput';
import _ from 'lodash';
import * as commonActions from "../../redux/actions/common"
import SearchIcon from '@material-ui/icons/Search';
import { Button, InputAdornment, Tooltip, Zoom, CircularProgress } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useLocation, useHistory } from "react-router-dom"
import { setData, getData, isSuperAdminAccess } from '../../utils';
import CustomReactSelect from '../../components/common/CustomReactSelect';
import Select from 'react-select'

const queryString = require('query-string');
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

export default function ManageProducts() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const [search, setSearch] = useState("")
    const [filters, setFilters] = useState({
        limit: 10,
        skip: 0,
        page: 0,
    })
    const [isActiveTab, setIsActiveTab] = useState(true)
    const [waitingTime, setWaitingTime] = useState("")

    //filter option
    const filterOptions = [
        { value: true, label: 'Active' },
        { value: false, label: 'In-Active' },
    ];


    //reducer
    const { lastSyncDate } = useSelector(state => state.auth)
    const { productThreshold, syncProductLoader = false } = useSelector(state => state.products);
    const thresholdValue = productThreshold.threshold;

    //refs
    let waitingTimeInterval = useRef(null)

    useEffect(() => {
        let newFilters = {}
        let searchT = ''
        let newActive = true
        if (location.search) {
            const { limit = 10, skip = 0, page = 0, searchTerm = '', active = true } = queryString.parse(location.search);
            searchT = searchTerm
            newActive = active === "true"
            newFilters = {
                limit: Number(limit),
                skip: Number(skip),
                page: Number(page)
            }
            setIsActiveTab(newActive)
            setFilters(newFilters)
            setSearch(searchT)
        }
        hitRequest({ ...newFilters, searchTerm: searchT, active: newActive })
        runWaitingTimeInterval()
        return () => {
            clearInterval(waitingTimeInterval.current)
        }
    }, [])

    useEffect(() => {
        dispatch(productsActions.getThreshold())
    }, []);

    const runWaitingTimeInterval = () => {
        if (getData("adminLastSync")) {
            const value = JSON.parse(getData("adminLastSync"))
            waitingTimeInterval.current = setInterval(() => {
                const diffInMin = Math.floor(((Date.now() - value) / 1000 / 60) << 0)
                if (diffInMin > 2) {
                    setWaitingTime("")
                    clearInterval(waitingTimeInterval.current)
                }
                let waitingMsg = ""
                if (diffInMin === 0 || diffInMin === 1) waitingMsg = "Wait for 2 min"
                if (diffInMin === 2) waitingMsg = "Wait for 1 min"
                setWaitingTime(waitingMsg)
            }, 2000);
        }
    }

    useEffect(() => {
        location.search = queryString.stringify({ ...filters, searchTerm: search, active: isActiveTab });
        history.push({
            pathname: '/manage_products',
            search: location.search
        })
    }, [filters, search, isActiveTab])


    const hitRequest = (filters = {}) => {
        dispatch(authActions.getLastSyncDate())
        dispatch(productsActions.getAllProducts({ ...filters, sortBy: 'active' }))

    }

    const handleChange = (e) => {
        const { value } = e.target
        let newFilters = { limit: 10, skip: 0, page: 0, searchTerm: value, active: isActiveTab }
        setSearch(value);
        setFilters({ limit: 10, skip: 0, page: 0 })
        debouncedSave(newFilters);
    }

    const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 500), []);

    const onChangePagination = (e, page) => {
        const skip = page * filters.limit
        setFilters({ ...filters, skip, page })
        hitRequest({ ...filters, skip, page, searchTerm: search, active: isActiveTab })
    }

    const onChangeRowsPerPage = (e) => {
        const newFilters = { limit: parseInt(e.target.value, 10), page: 0, skip: 0 }
        setFilters(newFilters)
        hitRequest({ ...newFilters, searchTerm: search, active: isActiveTab })
    }

    const onClickClearInputField = () => {
        if (!search) return
        const newFilters = { limit: 10, skip: 0, page: 0, }
        setFilters(newFilters)
        hitRequest({ ...newFilters, active: isActiveTab })
        setSearch('')
    }

    const onChangeSelectedTab = (e) => {
        const status = e?.value;
        const newFilters = { limit: 10, skip: 0, page: 0, }
        setIsActiveTab(status)
        setFilters(newFilters)
        hitRequest({ ...newFilters, searchTerm: search, active: status })
    }
    const onSyncBlazeProducts = () => {
        if (!syncProductLoader && !waitingTime) {
            dispatch(productsActions.syncProductAdmin()).then(res => {
                setData("adminLastSync", Date.now())
                setWaitingTime("Wait for 2 min")
                runWaitingTimeInterval()
            })
        }
    }

    let dt = (lastSyncDate && new Date(lastSyncDate)) || '';
    let la = dt.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });

    return (
        <Grid item xs={12} className="manage-product-section">
            <Grid item xs={12} className="last-sync">
                <div>
                    Last Sync : {la || "Syncing Now"}
                </div>
                {isSuperAdminAccess() ? <LightTooltip TransitionComponent={Zoom} title={`${syncProductLoader ? "Syncing is in Progress" : waitingTime ? waitingTime + " for next sync" : "Sync products from blaze"}`}>
                    <div className={`product-sync-div ${waitingTime ? "disabled-text" : ""}`} onClick={onSyncBlazeProducts}>
                        {syncProductLoader ? <CircularProgress size={18} /> : waitingTime ? waitingTime : "Sync Products"}
                    </div>
                </LightTooltip> : <div></div>}
            </Grid>
            <Grid item xs={12} >
                <Grid container  >
                    <Grid item xs={4} className="d-flex search-section">
                        <CustomInput
                            label="Search"
                            name="search"
                            placeholder="Search"
                            onChange={handleChange}
                            value={search}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                endAdornment: search ? <InputAdornment position="end" className="cur-pointer"><ClearIcon onClick={onClickClearInputField} /></InputAdornment> : null
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} ></Grid>
                    <Grid item xs={4} className="manage-top-tabs">
                        {/* Simple UI of Active / In-Active  Buttons  */}
                        {/* <div className="tabs-section-div"> */}
                        {/* <div className={isActiveTab ? 'selected-tab' : ''} onClick={() => onChangeSelectedTab(true)}>
                                <span>Active</span>
                            </div>
                            <div className={!isActiveTab ? 'selected-tab' : ''} onClick={() => onChangeSelectedTab(false)}>
                                <span>In-Active</span>
                            </div> */}
                        {/* </div> */}
                        <CustomReactSelect onChangeSelectedTab={onChangeSelectedTab} filterOptions={filterOptions} />

                    </Grid>
                    {/* Threshold Code  */}
                    {/* <Grid item xs={4}>
                        <div className="cus-threshold">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { dispatch(commonActions.openCustomModalDialog({thresholdValue}, "ThresholdModal")) }}
                                
                            >
                                {false ? <CircularProgress size={20} style={{ color: "white" }} /> : 'Threshold'}
                            </Button>
                        </div>
                    </Grid> */}
                </Grid>
            </Grid>



            <AllProducts
                onChangePagination={onChangePagination}
                onChangeRowsPerPage={onChangeRowsPerPage}
                filters={filters}
                searchTerm={search}
                isActiveTab={isActiveTab}
            />
        </Grid>
    );
}