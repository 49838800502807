import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from "react-router-dom"
import SingleProductDetails from './SingleProductsDetails';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import queryString from "query-string"

function SingleProductPage(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const { limit, skip, page, active } = queryString.parse(location.search);
        let newFilters = { limit, skip, page, active }
        newFilters = queryString.stringify(newFilters);
        location.search = newFilters
    }, [])

    return (
        <Grid container component="main" className={`${classes.root} single-product-page-container`}>
            <Grid item xs={12}>
                <Grid item xs={12} className="d-flex">
                    <div onClick={() => {
                        history.push({
                            pathname: "/manage_products",
                            search: location.search
                        })
                    }} className="cur-pointer d-flex align-center mb-1">
                        <KeyboardBackspaceIcon />
                        <span className="back-text">back</span>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <SingleProductDetails {...props} />
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },

}));


export default SingleProductPage