import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, FormGroup, Grid, FormLabel, MenuItem, Select, InputLabel, Checkbox, FormControlLabel, FormControl, Switch } from '@material-ui/core';
import * as promotionsActions from "../../redux/actions/promotions";
import { useDispatch, useSelector } from 'react-redux';
import CustomCheckbox from '../../components/common/CustomCheckbox';
import CustomInput from '../../components/common/CustomInput';
import { validatePromotionData } from '../../utilities/validations/promotion';
import { ColorLensOutlined } from '@material-ui/icons';
import * as commonActions from "../../redux/actions/common";
import { numRegex } from '../../utils';

const intialState = {
    name: "",
    active: false,
    promoCodes: [],
    enableMaxAvailable: false,
    maxAvailable: 0,
    enableLimitPerCustomer: false,
    limitPerCustomer: 0,
    mon: false,
    tues: false,
    wed: false,
    thur: false,
    fri: false,
    sat: false,
    sun: false,
    promoDesc: "",
    discountType: "",
    discountAmt: 0,
    // promocode: "",
};

function AddPromotions(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [addPromotionData, setAddPromotionData] = useState(intialState)
    // const [errors, setErrors] = useState("");
    const {
        name,
        active,
        promoCodes,
        enableMaxAvailable,
        maxAvailable,
        enableLimitPerCustomer,
        limitPerCustomer,
        promoDesc,
        mon,
        tues,
        wed,
        thur,
        fri,
        sat,
        sun,
        discountType,
        discountAmt,
        // promocode,
    } = addPromotionData;

    const dayCheckBoxData = [
        { name: "mon", label: "Monday", checked: mon },
        { name: "tues", label: "Tuesday", checked: tues },
        { name: "wed", label: "Wednesday", checked: wed },
        { name: "thur", label: "Thursday", checked: thur },
        { name: "fri", label: "Friday", checked: fri },
        { name: "sat", label: "Saturday", checked: sat },
        { name: "sun", label: "Sunday", checked: sun },
    ];

    const onCheckboxChangeHandler = (e) => {
        const { name, checked } = e.target;
        setAddPromotionData({ ...addPromotionData, [name]: checked })
    };

    const onHandleChangePromotion = (e) => {
        const { name, value } = e.target;

        let requiredValue = value;

        if (name === "maxAvailable" || name === "limitPerCustomer" || name === "discountAmt") {
            requiredValue = Number(value);
            if (!(numRegex.test(requiredValue)) || requiredValue < 0) return;
            if (addPromotionData?.discountType === "Percentage" && requiredValue > 100)
                return;
        }
        setAddPromotionData({ ...addPromotionData, [name]: requiredValue })
        // setErrors({ ...errors, [name]: "" })
    }

    const onAddPromotionSubmitHandler = () => {
        const promoArr = addPromotionData?.promoCodes?.split(",");
        const submitData = { ...addPromotionData, promoCodes: promoArr }

        dispatch(promotionsActions.addPromotionProducts(submitData))
            .then(res => {
                setAddPromotionData(intialState);
                dispatch(commonActions.closeCustomModalDialog());
                dispatch(promotionsActions.getAllPromotion())
            })
            .catch(error => { console.log(error) })
    }

    return (
        <Grid container component="main" className={`${classes.root} add-category-modal-container`}>
            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">
                    Add Promotion</Typography>
            </Grid>
            <Grid item xs={12} className="add-category-input">
                {/* <CustomDropDown
                    setFile={(v) => { handleChangeImages(v) }}
                    src={promotionImages && typeof promotionImages == "string" ? promotionImages : promotionImages && URL.createObjectURL(promotionImages) || ""}
                    imageProps={{ height: "200px" }}
                    accept=".jpeg,.jpg,.png"
                /> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4} >

                        <CustomInput
                            name="name"
                            value={name || ""}
                            onChange={(e) => { onHandleChangePromotion(e) }}
                            label="Title / Name"
                            className="w-100"
                            style={{ marginTop: '16px' }}

                        // error={errors.title}
                        />

                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <FormGroup row className="d-block">

                            <FormControl variant="outlined" className="w-100 perAmount-mt ">
                                <InputLabel id="demo-simple-select-outlined-label">Active or InActive</InputLabel>
                                <Select
                                    // fullWidth
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={active}
                                    onChange={(e) => { onHandleChangePromotion(e) }}
                                    name='active'
                                    label="Active or InActive"
                                >
                                    <MenuItem value={false} >Inactive</MenuItem>
                                    <MenuItem value={true} >Active</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>

                        <CustomInput
                            name="promoCodes"
                            label="Code"
                            className="w-100"
                            margin="normal"
                            value={promoCodes}
                            onChange={(e) => { onHandleChangePromotion(e) }}
                        />
                    </Grid>
                    <Grid item xs={4} className="m-0">

                        <Switch
                            name="enableMaxAvailable"
                            label="Enable Max Available"
                            value={enableMaxAvailable}
                            onChange={(e) => { onCheckboxChangeHandler(e) }}
                        />
                        <CustomInput
                            name="maxAvailable"
                            type="text"
                            label="Max Available"
                            className="w-100 mt-0"
                            // margin="normal"
                            value={maxAvailable || ""}
                            disabled={!enableMaxAvailable}
                            onChange={onHandleChangePromotion}
                        />
                    </Grid>
                    <Grid item xs={4}>



                        <Switch
                            name="enableLimitPerCustomer"
                            label="Enable Limit Per Customer"
                            value={enableLimitPerCustomer}
                            onChange={(e) => { onCheckboxChangeHandler(e) }}
                        />

                        <CustomInput
                            name="limitPerCustomer"
                            type="text"
                            label="Max Per Customer"
                            className="w-100 mt-0"
                            // margin="normal"
                            value={limitPerCustomer || ""}
                            disabled={!enableLimitPerCustomer}
                            onChange={(e) => { onHandleChangePromotion(e) }}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <div className="pt-2 bg-border">
                            <Typography >Auto Apply On </Typography>
                            {
                                dayCheckBoxData.map((data) => {
                                    const { name, label, checked } = data
                                    return <CustomCheckbox
                                        key={name}
                                        name={name}
                                        label={label}
                                        checked={checked}
                                        onChange={(e) => { onCheckboxChangeHandler(e) }}
                                    />
                                })
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} className="">
                        <CustomInput
                            name="promoDesc"
                            label="Promo Text"
                            className="w-100"
                            margin="normal"
                            value={promoDesc}
                            onChange={(e) => { onHandleChangePromotion(e) }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl variant="outlined" className="w-100 perAmount-mt product-align-single-meta">
                            <InputLabel id="demo-simple-select-outlined-label">Percentage or Amount</InputLabel>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={discountType}
                                onChange={(e) => { onHandleChangePromotion(e) }}
                                name='discountType'
                                label="Percentage or Amount"
                            >
                                <MenuItem value={'Percentage'} >Percentage</MenuItem>
                                <MenuItem value={'Cash'}  >Cash</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <CustomInput
                            name="discountAmt"
                            label="Amount"
                            type="text"
                            className="w-100 product-align-single-meta"
                            margin="normal"
                            value={discountAmt || ""}
                            onChange={(e) => { onHandleChangePromotion(e, "discountAmount") }}
                            disabled={!(discountType?.length)}
                        />
                    </Grid>

                    <Grid item xs={4} className="paddingTop-24 btn-custom">
                        <Button onClick={onAddPromotionSubmitHandler} variant="contained" color="primary" >Save</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default AddPromotions