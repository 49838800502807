import React, { useState, useEffect } from 'react';
import { initialDeliveryDays } from "../../constants"
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CustomMultiSelect from '../../components/common/CustomMultiSelect';
import { staticTimes } from "../../constants"
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../redux/actions/deliveryHours"
import * as commonActions from "../../redux/actions/common"
import { Button, Grid, TextField, Checkbox, FormGroup, FormControlLabel, FormControl } from '@material-ui/core';

const deliveryTime = { start: "00:00", end: "00:00" }

export default function AddDeliveryHoursModal() {

    const dispatch = useDispatch();

    let modalData = useSelector(state => state.common.modalData) || {};

    let isEdit = modalData && modalData.title === "Edit" ? true : false || false;

    const [deliveryHours, setDeliveryHours] = useState({
        locationName: "",
        locationCity: "",
        deliveryDays: {},
        timeInterval: 0,
        zipcode: []
    })

    const [deliveryDays] = useState([...initialDeliveryDays]);
    useEffect(() => {
        if (modalData && modalData.title === "Edit") {
            setDeliveryHours(modalData.data)

        }
    }, [modalData])

    const onChangeHandler = (e) => {
        const { name = "", value = "" } = e.target;
        const updatedDeliveryHours = { ...deliveryHours }
 
        if (name === "zipcode") {
            updatedDeliveryHours.zipcode = value.split(",")
        } else {
            updatedDeliveryHours[name] = value
        }

        setDeliveryHours(updatedDeliveryHours);

    }

    const onChangeDeliveryDays = (name, data, index) => {
        if (deliveryHours.deliveryDays.hasOwnProperty(data)) {
            let tempDeliveryHours = deliveryHours
            delete tempDeliveryHours.deliveryDays[data]
            setDeliveryHours({ ...tempDeliveryHours })
        } else {
            let newDeliveryHours = { ...deliveryHours }
            newDeliveryHours = { ...newDeliveryHours, [name]: { ...newDeliveryHours[`${name}`], [data]: [{ ...deliveryTime }] } }

            let newDeliveryDays = Object.entries(newDeliveryHours.deliveryDays)
            newDeliveryDays = newDeliveryDays.sort((first, secound) => {
                const firstDay = deliveryDays.find(item => item.day === first[0])
                const secoundDay = deliveryDays.find(item => item.day === secound[0])
                return firstDay.key - secoundDay.key
            })
            newDeliveryDays = Object.fromEntries(newDeliveryDays)
            setDeliveryHours((prevState) => {
                return { ...prevState, deliveryDays: newDeliveryDays }
            })
        }
    }

    const onDeliveryTimeChangeHandle = (day, key, time, val) => {

        const { deliveryDays = {} } = deliveryHours || {};
        if (!val) return
        deliveryDays[day].forEach((item, index, arr) => {
            if (index === key) {
                arr[index] = { ...item, [time]: val.value }
            }
        })
        setDeliveryHours(prevState => {
            return { ...prevState, deliveryDays: { ...deliveryDays } }
        })
    };

    const addDays = (key) => {
        let delDays = { ...deliveryHours.deliveryDays }
        let time = delDays[key];

        time.push(deliveryTime)

        setDeliveryHours({
            ...deliveryHours,
            deliveryDays: { ...delDays, [key]: time }
        })
    };

    const onDeleteTimings = (dayKey, timeKey) => {
        let delDays = { ...deliveryHours.deliveryDays }
        let day = delDays[dayKey];
        day.splice(timeKey, 1)
        setDeliveryHours({
            ...deliveryHours,
            deliveryDays: { ...delDays, [dayKey]: day }
        })

    };

    const onSubmitHandler = () => {
        if (isEdit) {
            actions.updateDeliveryHours(deliveryHours._id, deliveryHours).then(() => {
                dispatch(actions.getDeliveryHours())
                dispatch(commonActions.closeCustomModalDialog({}, "closeDeliveryHoursModal"))
            }).catch(() => { })
        } else {
            actions.postDeliveryHours(deliveryHours).then(() => {
                dispatch(actions.getDeliveryHours())
                dispatch(commonActions.closeCustomModalDialog({}, "closeDeliveryHoursModal"))
            }).catch(() => { })
        }
    };

    return (
        <Grid container component="main" className="add-zipcode-modal-container">
            <Grid item xs={12} className="modal-heading">
                <h2>Delivery Hours</h2>
            </Grid>
            <Grid item xs={12} className="add-category-input">
                <div className="rep-modal-field">
                    <h3>Location Name</h3>
                    <TextField
                        className="blaze-zipcode-textarea"
                        type="text"
                        value={deliveryHours.locationName}
                        name="locationName"
                        fullWidth
                        variant="outlined"
                        onChange={onChangeHandler}
                    />
                    {/* {errors.description && <p className="error">{errors.description}</p>} */}
                </div>

                <div className="rep-modal-field">
                    <h3>Location City</h3>
                    <TextField
                        className="blaze-zipcode-textarea"
                        type="text"
                        value={deliveryHours.locationCity}
                        name="locationCity"
                        fullWidth
                        variant="outlined"
                        onChange={onChangeHandler}
                    />
                    {/* {errors.description && <p className="error">{errors.description}</p>} */}
                </div>

                <div className="rep-modal-field">
                    <h3>Delivery Days</h3>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                            {deliveryDays.map((data, index) => {
                                const deliveryDaysKeyMap = Object.keys(deliveryHours.deliveryDays)
                                const isChecked = deliveryDaysKeyMap.includes(data.day)
                                return (
                                    <FormControlLabel
                                        name="deliveryDays"
                                        value={data.day}
                                        onChange={(data) => onChangeDeliveryDays(data.target.name, data.target.value, index)}
                                        control={<Checkbox color="primary" checked={isChecked} />}
                                        label={data.day}
                                        labelPlacement="end"
                                    />
                                );
                            })}
                        </FormGroup>
                    </FormControl>
                </div>
                <div className="rep-modal-field">
                    <h3>Add New Delivery Hours</h3>
                    <Grid container >
                        {
                            deliveryHours && deliveryHours.deliveryDays && Object.entries(deliveryHours.deliveryDays).map((devliveryDayItem, devliveryDayIndex) => {
                                return <Grid item md={12} className="pt-1" key={devliveryDayIndex}>
                                    <h3>{devliveryDayItem[0]}</h3>
                                    {
                                        devliveryDayItem && devliveryDayItem[1] && devliveryDayItem[1].length ? devliveryDayItem[1].map((devliveryTimeItem, devliverTimeItem) => {
                                            console.log(devliveryTimeItem, "devliveryTimeItem")
                                            const selectedStartTime = staticTimes.find(item => item.value === devliveryTimeItem.start)
                                            const selectedEndTime = staticTimes.find(item => item.value === devliveryTimeItem.end)
                                            return (
                                                <>
                                                    <div className="delivery-hours-timing">

                                                        <CustomMultiSelect
                                                            label="Start Time"
                                                            name={"label1"}
                                                            options={staticTimes || []}
                                                            onChange={(e, val) => { onDeliveryTimeChangeHandle(devliveryDayItem[0], devliverTimeItem, "start", val) }}
                                                            value={selectedStartTime || {}}
                                                            multiple={false}
                                                            required
                                                            disableCloseOnSelect={false}
                                                            className="hours-field"
                                                        />

                                                        <CustomMultiSelect
                                                            label="End Time"
                                                            name={"label1"}
                                                            options={staticTimes || []}
                                                            onChange={(e, val) => { onDeliveryTimeChangeHandle(devliveryDayItem[0], devliverTimeItem, "end", val) }}
                                                            value={selectedEndTime || {}}
                                                            multiple={false}
                                                            required
                                                            disableCloseOnSelect={false}
                                                            className="hours-field"
                                                        />
                                                        {devliverTimeItem !== 0 ? <RemoveCircleIcon className="remove-field" color="secondary" onClick={() => onDeleteTimings(devliveryDayItem[0], devliverTimeItem)} /> : null}
                                                    </div>

                                                </>
                                            )
                                        }) : null
                                    }
                                    <div className="add-more-fields">
                                        <p onClick={() => addDays(devliveryDayItem[0])}>Add New Time Slot</p>
                                    </div>
                                    {/* <AddCircleSharpIcon className="add-field" color="primary" onClick={() => addDays(devliveryDayItem[0])} /> */}
                                </Grid>
                            })
                        }

                    </Grid>
                </div>

                <div className="rep-modal-field">
                    <h3>Time interval in hours</h3>
                    <TextField
                        className="blaze-zipcode-textarea"
                        type="number"
                        value={deliveryHours.timeInterval}
                        name="timeInterval"
                        fullWidth
                        variant="outlined"
                        onChange={onChangeHandler}
                    />
                    {/* {errors.description && <p className="error">{errors.description}</p>} */}
                </div>

                <div className="rep-modal-field">
                    <h3>Zip Codes Covered (Comma Separated List)</h3>
                    <TextField
                        className="blaze-zipcode-textarea"
                        name="zipcode"
                        type="text"
                        value={deliveryHours.zipcode}
                        multiline
                        fullWidth
                        rows={4}
                        variant="outlined"
                        onChange={onChangeHandler}
                    />
                    {/* {errors.description && <p className="error">{errors.description}</p>} */}
                </div>

                <div className="d-flex jc-ctr add-button">
                    <Button variant="contained" color="primary" fullWidth onClick={onSubmitHandler}>Submit</Button>
                </div>
            </Grid>
        </Grid>
    );
}
