import React from 'react';
import {
    Grid,
    FormGroup,
    Button,
    Typography,
    TextField,
    Switch,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextareaAutosize,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomCheckbox from '../../components/common/CustomCheckbox';
import CustomInput from '../../components/common/CustomInput';
import { getProductUnitByCategoryId, capitalizeString } from '../../utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { imagesdata } from '../../constants'

function EditProductDetails(props) {

    const {
        infoAndEffectsData,
        singleProductDetailData,
        data,
        onChangeCheckbox,
        handleSubmit,
        onChangeBrandDescription,
        productStatus,
        onChangeProductStatus
    } = props;

    const {
        category,
        brand,
        flowerType,
        thc,
        cbd,
        cbn,
        unitPrice,
        potencyAmount,
        description,
        priceBreaks,
        adminProductName = '',
        bmwCategoryName = '',
        isCopyText = false,
    } = singleProductDetailData;

    let infoEffectObj = infoAndEffectsData?.length && infoAndEffectsData?.filter((v) => v?.strainType === (flowerType?.toLowerCase()))[0]
    let infoEffectArr = (infoEffectObj?.infoEffects) || [];
    let salesPrice = (priceBreaks?.length && priceBreaks[0]?.salePrice) || null;
    const unitTypeByCategoryName = getProductUnitByCategoryId(bmwCategoryName, { thc, cbd, cbn }, potencyAmount);
    let focusKeywordStr = (data?.focusKeyword?.length) ? data?.focusKeyword?.toString() : "";

    return (
        <Grid container>
            <Grid item xs={12} className="product-details-listing txt-left">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h4">Edit Menu Item</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className="text-right">
                            <strong> Status :</strong>
                            <Switch
                                checked={productStatus}
                                onChange={onChangeProductStatus}
                                color="primary"
                                name="active"
                            />
                            <p style={{ margin: 0 }}>
                                {productStatus ? <span>This item is <span style={{ color: "green", fontWeight: 600 }}>ACTIVE</span> and will be shown on your menu.</span> : <span>This item is <span style={{ color: "red", fontWeight: 600 }}>INACTIVE</span> and will not be shown on your menu.</span>}
                            </p>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> Category :</strong> <span> {bmwCategoryName || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> Sub-category :</strong> <span> {(category?.name) || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={12} className="py-2">
                        <Typography ><strong> Admin Product Name :</strong> <span> {adminProductName || "-"}</span></Typography>
                    </Grid>

                    <Grid item xs={12} className="py-2">
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Typography ><strong>Website Product Name :</strong></Typography>
                            </Grid>
                            <Grid item xs={12} md={12} className="pt-2">
                                <CustomInput
                                    label="Website Product Name"
                                    placeholder="Enter Website Product Name"
                                    name="websiteProductName"
                                    value={(data?.websiteProductName) || ""}
                                    fullWidth
                                    onChange={(e) => { onChangeBrandDescription(e) }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> Brand :</strong> <span> {(brand && brand.name) || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> Type :</strong> <span> {flowerType || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> THC Content :</strong> <span> {unitTypeByCategoryName.thc || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> CBD Content :</strong> <span> {unitTypeByCategoryName.cbd || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> CBN Content :</strong> <span> {unitTypeByCategoryName.cbn || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> Price :</strong> <span> ${parseFloat(unitPrice).toFixed(2) || "-"}</span></Typography>
                    </Grid>

                    {
                        salesPrice ?
                            <Grid item xs={12} className="py-2">
                                <Typography ><strong> Sale Price :</strong> <span> ${parseFloat(salesPrice).toFixed(2) || "-"}</span></Typography>
                            </Grid>
                            : null
                    }
                    <Grid item xs={12} className="py-2">
                        <Typography ><strong> Description :</strong> <span> {description || "-"}</span></Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className='py-1 px-3'>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className='accordion-style'
                    >
                        <Typography >Product SEO Data</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} className="product-align-single-meta">
                                        <CustomInput
                                            fullWidth
                                            label="Meta Titile"
                                            value={data?.metaTitle}
                                            name="metaTitle"
                                            onChange={onChangeBrandDescription}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomInput
                                            fullWidth
                                            label="Meta Description"
                                            value={data?.metaDescription}
                                            name="metaDescription"
                                            onChange={onChangeBrandDescription}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInput
                                    fullWidth
                                    label="Focus KeyWords"
                                    value={focusKeywordStr}
                                    name="focusKeyword"
                                    onChange={onChangeBrandDescription}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    // className="blaze-zipcode-textarea"
                                    label="SEO Content"
                                    fullWidth
                                    variant="outlined"
                                    value={data?.seoContent}
                                    name="seoContent"
                                    onChange={onChangeBrandDescription}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12} className='py-1 px-3'>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        name="metaTitle"
                        className='accordion-style'

                    >
                        <Typography >Product Effects</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className="effect-align-checkbox" >
                            {
                                infoEffectArr?.length ?
                                    <FormGroup className="effects-listing" >
                                        <Grid container   >
                                            {
                                                infoEffectArr?.length ? infoEffectArr?.map((item, index) => {
                                                    const { title } = item;
                                                    const capatlizedTitle = capitalizeString(title);
                                                    let value = data?.infoEffects?.includes(title);
                                                    const selectedImage = imagesdata?.filter(data => data.title === title);
                                                    return <Grid item md={3} className='innerGridstyle'  >
                                                        <div key={index} className={`effect-chkbox ${value ? "active" : ""}`}
                                                         onClick={(e) => onChangeCheckbox(e, title)
                                                        }
                                                         >
                                                            <img src={selectedImage[0]?.src || "/images/ProductEffectImage/default.svg"} alt={selectedImage[0]?.alt || "default_image"} />
                                                            <CustomCheckbox
                                                                label={capatlizedTitle}
                                                                checked={value || false}
                                                               
                                                            />
                                                        </div>
                                                    </Grid>
                                                })
                                                    :
                                                    null
                                            }
                                        </Grid>
                                    </FormGroup>
                                    :
                                    null
                            }
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12} className='py-1 px-3'>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className='accordion-style'
                    >
                        <Typography >Product Meta Data</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} className="product-align-single-meta">
                                        <CustomInput
                                            fullWidth
                                            label="Product Name"
                                            name="websiteProductName"
                                            value={data?.websiteProductName}
                                            defaultValue="Product Name"
                                            onChange={onChangeBrandDescription}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomInput
                                            fullWidth
                                            label="Product Description"
                                            name="productDescription"
                                            value={data?.productDescription}
                                            onChange={onChangeBrandDescription}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TextareaAutosize
                                    fullWidth
                                    placeholder="Brand Description"
                                    name="brandDescription"
                                    value={data?.brandDescription}
                                    onChange={onChangeBrandDescription}
                                    maxLength
                                    className="w-100"
                                    multiline
                                    rowsMin={5}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className='disclamimer-border'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <CustomInput
                                                className="input"
                                                fullWidth
                                                label="Product Disclaimer"
                                                name="disclaimerText"
                                                value={(data?.disclaimerText) || ""}
                                                onChange={(e) => { onChangeBrandDescription(e) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="copy-to-all-products">
                                            <CustomCheckbox
                                                label="Copy to all products"
                                                name="isCopyText"
                                                value={data?.isCopyText}
                                                onChange={onChangeBrandDescription}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12} className='px-3 py-2'>
                <div className="product-save-btn">
                    <Button
                        onClick={(e) => { handleSubmit(e) }}
                        variant="contained"
                        color="primary">
                        Save
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },

}));


export default EditProductDetails