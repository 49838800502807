import React from 'react';
import { TableCell, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CommonLoading from "../../components/common/CommonLoading"
import { REDIRECT_URL } from '../../constants';
import { flowerTypeImg } from '../../utils';
import CustomProductTable from '../../components/common/CustomProductTable';

function AllProducts(props) {
    const { onChangePagination = () => { }, filters = {}, onChangeRowsPerPage = () => { }, searchTerm = '', isActiveTab = true } = props


    const { allProducts, isLoading } = useSelector(state => state.products)
    const { values, total = 0 } = allProducts

    return (
        <React.Fragment>
            <CustomProductTable
                tableHeading={["S.no", "Product", "Website product Name", "Brand", "Category", "Sub-category", "Type", "Price", "View Product"]}
                rowsData={createDataProducts(values, filters, searchTerm, isActiveTab)}
                total={total}
                filters={filters}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePagination={onChangePagination}
            />
            <CommonLoading isLoading={isLoading} />
        </React.Fragment>
    );
}

export default AllProducts


function createDataProducts(data, filters, searchTerm, isActiveTab) {

    return data && data && data.length ?
        data.map((item, index) => {
            const { name, id, brand, category, flowerType, unitPrice, productSlug, bmwCategoryName, websiteProductName = '', showInWidget = false } = item
            return <React.Fragment key={id}>
                <TableCell >{((filters.page) * filters.limit) + (index + 1)}</TableCell>
                <TableCell>
                    <Link to={`/manage_products/${id}?limit=${filters.limit}&skip=${filters.skip}&page=${filters.page}&searchTerm=${searchTerm}&active=${isActiveTab}`} className="link-tag">{name ? name : '-'}</Link>
                </TableCell>
                <TableCell>{websiteProductName || "-"}</TableCell>
                <TableCell>{(brand && brand.name) || "-"}</TableCell>
                <TableCell>{bmwCategoryName || "-"}</TableCell>
                <TableCell>{(category && category.name) || "-"}</TableCell>
                <TableCell>
                    <div className="d-flex algn-itm-ctr">
                        {flowerType || "N/A"}
                        <div className="flowertype-list">{flowerTypeImg(flowerType)}</div>
                    </div>
                </TableCell>
                <TableCell >${parseFloat(unitPrice).toFixed(2)}</TableCell>
                <TableCell >
                    {showInWidget ? <Link to="#" onClick={() => { window.open(`${REDIRECT_URL + "shop/product/" + productSlug}`, '_blank') }} className="link-tag btn">View</Link>
                        : <Tooltip title="In-active Product">
                            <Link to="#" className={`link-tag ${!showInWidget ? 'inactive-tag' : ''} btn`}>View</Link>
                        </Tooltip>}

                </TableCell>
            </React.Fragment>
        }) : []
}